import ElasticPools from '../../views/components/wizard/steps-with-validation/ElasticPool';
import mock from '../mock';
const data = {
  faqData: {
    // payment
    resourcegroups: {
      icon: 'Folder',
      title: 'Resource Groups',
      subtitle: 'What is a resource group?',
      qandA: [
        {
          question: 'What is a resource group?',
          ans: 'A Resource Group in Azure is nothing but a logical container where you can create Azure resources. A resource group created in a specific region can contain the resources created in the other regions.',
        },
        {
          question: 'Can move resources between groups?',
          ans: 'Yes, but not with the onboarding tool, that must be done in the Azure Portal',
        },
        {
          question:
            "Can I change the name of a resource group once it's created?",
          ans: 'No. You can only add resources, columns (in the Portal), or delete the resource group. All this must be done in the Azure Portal.',
        },
        {
          question: 'Is there a cost associated with the resource group?',
          ans: 'No. Only resources in the group incur costs. These can vary depending on the region that the resource is created in.',
        },
        {
          question: 'Can I see the total cost of all the resources in a group?',
          ans: 'Yes. Costs can be shown in the Azure Portal. Budgets can be applied to each resource group and cost alerts can be created for when the cost approaches or exceeds the budget.',
        },
      ],
    },
    // delivery
    storagecontainer: {
      icon: 'Book',
      title: 'Storage containers',
      subtitle: 'all about storage.',
      qandA: [
        {
          question: 'What is a storage container?',
          ans: "A storage container is a location used to store binary files such as pictures, audio or documents. It's also used to stored the database templates to create new clients.",
        },
        {
          question: "What's inside a storage container?",
          ans: 'The storage containers in Azure can hold File Shares (a.k.a. sharepoint shares), queues, tables (for Excel like data) and blobs. Blobs or Binarly Large OBjects are what FleetWave uses to store its data.',
        },
        {
          question: 'How do I add files into a storage container?',
          ans: 'For uploading new file templates use this Onbboarding app by going to Storage->Upload BACPAC. All other changes can be made by using Microsofts Storage Explorer : https://azure.microsoft.com/en-gb/features/storage-explorer/',
        },
      ],
    },
    // cancellation and return
    databases: {
      icon: 'Database',
      title: 'Databases and Elastic Pools',
      subtitle: 'FleetWave data storage',
      qandA: [
        {
          question: 'What is an Azure Elastic Pool?',
          ans: "A pool that's elastic.",
        },
        {
          question: 'What about data sovereignty?',
          ans: 'Data should be located in the country of the client so FleetWave is compliant with all the countries data protection laws.',
        },
      ],
    },
    // my orders
    dnsManagement: {
      icon: 'Bookmark',
      title: 'DNS Management',
      subtitle: 'How to manage client DNS records?',
      qandA: [
        {
          question: 'What is a client subdomain?',
          ans: 'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
        },
        {
          question: 'Can I change the name once the clients been onboarded?',
          ans: 'Short answer NO. Long answer not without contacting a developer to manipulate the Client record in the Catalog database and fix the naming in the Azure Portal.',
        },
        {
          question: 'How can I ship my order to an international location?',
          ans: 'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
        },
        {
          question:
            'I missed the delivery of my order today. What should I do?',
          ans: 'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
        },
        {
          question: 'The delivery of my order is delayed. What should I do?',
          ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        },
      ],
    },
    // product and services
  },
};

mock.onGet('/faq/data').reply((config) => {
  const { q = '' } = config.params;
  const queryLowered = q.toLowerCase();

  const filteredData = {};

  Object.entries(data.faqData).forEach((entry) => {
    const [categoryName, categoryObj] = entry;
    const filteredQAndAOfCategory = categoryObj.qandA.filter((qAndAObj) => {
      return qAndAObj.question.toLowerCase().includes(queryLowered);
    });
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = {
        ...categoryObj,
        qandA: filteredQAndAOfCategory,
      };
    }
  });

  return [200, filteredData];
});
