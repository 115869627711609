import * as config from '../config/config';

const {
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_API_CLIENT_ID,
  REACT_APP_AUTH_AUTHORITY_DOMAIN,
  REACT_APP_AUTH_TENANT,
  REACT_APP_AUTH_ADMIN_EMAILS,
} = config;

export const adminEmails = (REACT_APP_AUTH_ADMIN_EMAILS || '').split(',');

export const userFlows = {
  signUpSignIn: 'B2C_1_signin1',
  forgotPassword: 'B2C_1_passwordreset1',
};

export const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority: `https://${REACT_APP_AUTH_AUTHORITY_DOMAIN}/${REACT_APP_AUTH_TENANT}.onmicrosoft.com/${userFlows.signUpSignIn}`,
    },
    forgotPassword: {
      authority: `https://${REACT_APP_AUTH_AUTHORITY_DOMAIN}/${REACT_APP_AUTH_TENANT}.onmicrosoft.com/${userFlows.forgotPassword}`,
    },
  },
  authorityDomain: REACT_APP_AUTH_AUTHORITY_DOMAIN,
};

const redirectUrl =
  typeof window !== 'undefined'
    ? window.location.origin
    : 'http://localhost:3000';

export const msalConfig = {
  auth: {
    clientId: REACT_APP_AUTH_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: redirectUrl,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const apiAccessScope = `https://${REACT_APP_AUTH_TENANT}.onmicrosoft.com/${REACT_APP_AUTH_API_CLIENT_ID}/access_as_user`;

// Add scopes here for id token to be used at MS Identity Platform endpoints
// The following scopes are always included: 'openid', 'profile', 'offline_access'
export const loginRequest = {
  scopes: [apiAccessScope],
};
