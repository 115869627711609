function getRuntimeEnv(key) {
  const runtimeKey = `RUNTIME_ENV_${key}`;
  return window[runtimeKey] !== `__${key}__` ? window[runtimeKey] : null;
}

export const REACT_APP_ONBOARD_API =
  getRuntimeEnv('REACT_APP_ONBOARD_API') || process.env.REACT_APP_ONBOARD_API;

export const REACT_APP_AUTH_ADMIN_EMAILS =
  getRuntimeEnv('REACT_APP_AUTH_ADMIN_EMAILS') ||
  process.env.REACT_APP_AUTH_ADMIN_EMAILS;

export const {
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_API_CLIENT_ID,
  REACT_APP_AUTH_AUTHORITY_DOMAIN,
  REACT_APP_AUTH_TENANT,
  REACT_APP_BASENAME,
} = process.env;
