import * as signalR from '@microsoft/signalr';
import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Avatar from '@components/avatar';
import { Bell, Check, X, AlertTriangle, Info, Cloud } from 'react-feather';
import useSound from 'use-sound';
import popSfx from './sounds/popSfx.mp3';
import * as config from './config/config';

const PrimaryToast = (message) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color="primary" icon={<Bell size={12} />} />
        <h6 className="toast-title">{message.title}</h6>
      </div>
      <small className="text-muted">{message.time}</small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {message.body}
      </span>
    </div>
  </Fragment>
);

const SuccessToast = (message) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color="success" icon={<Check size={12} />} />
        <h6 className="toast-title">{message.title}</h6>
      </div>
      <small className="text-muted text-right">{message.time}</small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {message.body}
      </span>
    </div>
  </Fragment>
);

const ErrorToast = (message) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color="danger" icon={<X size={12} />} />
        <h6 className="toast-title">{message.title}</h6>
      </div>
      <small className="text-muted">{message.time}</small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {message.body}
      </span>
    </div>
  </Fragment>
);

const WarningToast = (message) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color="warning" icon={<AlertTriangle size={12} />} />
        <h6 className="toast-title">{message.title}</h6>
      </div>
      <small className="text-muted">{message.time}</small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {message.body}
      </span>
    </div>
  </Fragment>
);

const InfoToast = (message) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color="info" icon={<Info size={12} />} />
        <h6 className="toast-title">{message.title}</h6>
      </div>
      <small className="text-muted">{message.time}</small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {message.body}
      </span>
    </div>
  </Fragment>
);

const AzureToast = (message) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <Avatar size="sm" color="info" icon={<Cloud size={12} />} />
        <h6 className="toast-title">{message.title}</h6>
      </div>
      <small className="text-muted">{message.time}</small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        {message.body}
      </span>
    </div>
  </Fragment>
);

const SignalRHub = () => {
  const [connection, setConnection] = useState(null);
  const [inputText, setInputText] = useState('');
  const [playPop] = useSound(
    popSfx,
    {
      onend: () => {
        console.info('Sound ended!');
      },
    },
    []
  );

  useEffect(() => {
    const connect = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withUrl(`${config.REACT_APP_ONBOARD_API}/signalr`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('Azure', (message) => {
            toast.info(<AzureToast {...message} />, {
              hideProgressBar: false,
            });
            console.log('PLAYING!');
            playPop();
          });
          connection.on('Information', (message) => {
            toast.info(<InfoToast {...message} />, {
              hideProgressBar: false,
            });
            playPop();
          });
          connection.on('Warning', (message) => {
            toast.warning(<WarningToast {...message} />, {
              hideProgressBar: false,
            });
            playPop();
          });
          connection.on('Success', (message) => {
            toast.success(<SuccessToast {...message} />, {
              hideProgressBar: false,
            });
            playPop();
          });
          connection.on('Error', (message) => {
            toast.error(<ErrorToast {...message} />, {
              hideProgressBar: false,
            });
            playPop();
          });
        })
        .catch((error) => console.error(error));
    }
  }, [connection]);

  return null;
};

export default SignalRHub;
