// search & replace for converting c# class to Json Option object for drop down.
// ^\s*(?<lab>\w+)\s+=\s+(?<val>\d+)
// { label: "${lab}", value: "${val}" }

// https://docs.microsoft.com/en-us/azure/azure-sql/database/resource-limits-dtu-elastic-pools
const ElasticPoolEdition = [
  { label: 'Basic', value: 'Basic' },
  { label: 'Standard', value: 'Standard' },
  { label: 'Premium', value: 'Premium' },
  // { label: "GeneralPurpose", value: "GeneralPurpose" },
  // { label: "BusinessCritical", value: "BusinessCritical" }
];

const SqlElasticPoolBasicMinEDTUs = [
  /// 0 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_0', value: '0' },
  /// 5 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_5', value: '5' },
];

const SqlElasticPoolBasicMaxEDTUs = [
  /// Maximum 5 eDTUs available per each database.
  { label: 'eDTU_5', value: '5' },
];

const SqlElasticPoolStandardMinEDTUs = [
  /// 0 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_0', value: '0' },
  /// 20 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_20', value: '20' }, // 0x00000014
  /// 50 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_50', value: '50' }, // 0x00000032
  /// 100 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_100', value: '100' }, // 0x00000064
  /// 200 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_200', value: '200' }, // 0x000000C8
  /// 300 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_300', value: '300' }, // 0x0000012C
  /// 400 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_400', value: '400' }, // 0x00000190
  /// 800 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_800', value: '800' }, // 0x00000320
  /// 1200 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_1200', value: '1200' }, // 0x000004B0
  /// 1600 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_1600', value: '1600' }, // 0x00000640
  /// 2000 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_2000', value: '2000' }, // 0x000007D0
  /// 2500 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_2500', value: '2500' }, // 0x000009C4
  /// 3000 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_3000', value: '3000' }, // 0x00000BB8
];
const SqlElasticPoolStandardMaxEDTUs = [
  /// Maximum 10 eDTUs available per each database.
  { label: 'eDTU_10', value: '10' }, // 0x0000000A
  /// Maximum 20 eDTUs available per each database.
  { label: 'eDTU_20', value: '20' }, // 0x00000014
  /// Maximum 50 eDTUs available per each database.
  { label: 'eDTU_50', value: '50' }, // 0x00000032
  /// Maximum 100 eDTUs available per each database.
  { label: 'eDTU_100', value: '100' }, // 0x00000064
  /// Maximum 200 eDTUs available per each database.
  { label: 'eDTU_200', value: '200' }, // 0x000000C8
  /// Maximum 300 eDTUs available per each database.
  { label: 'eDTU_300', value: '300' }, // 0x0000012C
  /// Maximum 400 eDTUs available per each database.
  { label: 'eDTU_400', value: '400' }, // 0x00000190
  /// Maximum 800 eDTUs available per each database.
  { label: 'eDTU_800', value: '800' }, // 0x00000320
  /// Maximum 1200 eDTUs available per each database.
  { label: 'eDTU_1200', value: '1200' }, // 0x000004B0
  /// Maximum 1600 eDTUs available per each database.
  { label: 'eDTU_1600', value: '1600' }, // 0x00000640
  /// Maximum 2000 eDTUs available per each database.
  { label: 'eDTU_2000', value: '2000' }, // 0x000007D0
  /// Maximum 2500 eDTUs available per each database.
  { label: 'eDTU_2500', value: '2500' }, // 0x000009C4
  /// Maximum 3000 eDTUs available per each database.
  { label: 'eDTU_3000', value: '3000' }, // 0x00000BB8
];

const SqlElasticPoolStandardStorage = [
  { label: 'Max50Gb', value: '53687091200' }, // 0x0000000C80000000
  /// 100 GB storage capacity available for the databases.
  { label: 'Max100Gb', value: '107374182400' }, // 0x0000001900000000
  /// 150 GB storage capacity available for the databases.
  { label: 'Max150Gb', value: '161061273600' }, // 0x0000002580000000
  /// 200 GB storage capacity available for the databases.
  { label: 'Max200Gb', value: '214748364800' }, // 0x0000003200000000
  /// 250 GB storage capacity available for the databases.
  { label: 'Max250Gb', value: '268435456000' }, // 0x0000003E80000000
  /// 300 GB storage capacity available for the databases.
  { label: 'Max300Gb', value: '322122547200' }, // 0x0000004B00000000
  /// 400 GB storage capacity available for the databases.
  { label: 'Max400Gb', value: '429496729600' }, // 0x0000006400000000
  /// 500 GB storage capacity available for the databases.
  { label: 'Max500Gb', value: '536870912000' }, // 0x0000007D00000000
  /// 750 GB storage capacity available for the databases.
  { label: 'Max750Gb', value: '805306368000' }, // 0x000000BB80000000
  /// 800 GB storage capacity available for the databases.
  { label: 'Max800Gb', value: '858993459200' }, // 0x000000C800000000
  /// 1 TB storage capacity available for the databases.
  { label: 'Max1Tb', value: '1099511627776' }, // 0x0000010000000000
  /// 1200 GB storage capacity available for the databases.
  { label: 'Max1200Gb', value: '1288490188800' }, // 0x0000012C00000000
  /// 1280 GB storage capacity available for the databases.
  { label: 'Max1280Gb', value: '1374389534720' }, // 0x0000014000000000
  /// 1536 GB storage capacity available for the databases.
  { label: 'Max1536Gb', value: '1649267441664' }, // 0x0000018000000000
  /// 1600 GB storage capacity available for the databases.
  { label: 'Max1600Gb', value: '1717986918400' }, // 0x0000019000000000
  /// 1792 GB storage capacity available for the databases.
  { label: 'Max1792Gb', value: '1924145348608' }, // 0x000001C000000000
  /// 2000 GB storage capacity available for the databases.
  { label: 'Max2000Gb', value: '2147483648000' }, // 0x000001F400000000
  /// 2 TB storage capacity available for the databases.
  { label: 'Max2Tb', value: '2199023255552' }, // 0x0000020000000000
  /// 2304 GB storage capacity available for the databases.
  { label: 'Max2304Gb', value: '2473901162496' }, // 0x0000024000000000
  /// 2560 GB storage capacity available for the database.
  { label: 'Max2560Gb', value: '2748779069440' }, // 0x0000028000000000
  /// 2816 GB storage capacity available for the database.
  { label: 'Max2816Gb', value: '3023656976384' }, // 0x000002C000000000
  /// 3000 GB storage capacity available for the databases.
  { label: 'Max3000Gb', value: '3221225472000' }, // 0x000002EE00000000
  /// 3 TB storage capacity available for the databases.
  { label: 'Max3Tb', value: '3298534883328' }, // 0x0000030000000000
  /// 3328 GB storage capacity available for the database.
  { label: 'Max3328Gb', value: '3573412790272' }, // 0x0000034000000000
  /// 3584 GB storage capacity available for the database.
  { label: 'Max3584Gb', value: '3848290697216' }, // 0x0000038000000000
  /// 3840 GB storage capacity available for the database.
  { label: 'Max3840Gb', value: '4123168604160' }, // 0x000003C000000000
  /// 4 TB storage capacity available for the database.
  { label: 'Max4Tb', value: '4398046511104' }, // 0x0000040000000000
];

const SqlElasticPoolPremiumMinEDTUs = [
  /// 0 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_0', value: '0' },
  /// 0 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_25', value: '25' }, // 0x00000019
  /// 50 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_50', value: '50' }, // 0x00000032
  /// 75 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_75', value: '75' }, // 0x0000004B
  /// 125 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_125', value: '125' }, // 0x0000007D
  /// 250 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_250', value: '250' }, // 0x000000FA
  /// 500 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_500', value: '500' }, // 0x000001F4
  /// 1000 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_1000', value: '1000' }, // 0x000003E8
  /// 1750 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_1750', value: '1750' }, // 0x000006D6
  /// 4000 reserved minimum eDTUs available for each database in the pool.
  { label: 'eDTU_4000', value: '4000' }, // 0x00000FA0
];

const SqlElasticPoolPremiumMaxEDTUs = [
  /// Maximum 25 eDTUs available per each database.
  { label: 'eDTU_25', value: '25' }, // 0x00000019
  /// Maximum 50 eDTUs available per each database.
  { label: 'eDTU_50', value: '50' }, // 0x00000032
  /// Maximum 75 eDTUs available per each database.
  { label: 'eDTU_75', value: '75' }, // 0x0000004B
  /// Maximum 125 eDTUs available per each database.
  { label: 'eDTU_125', value: '125' }, // 0x0000007D
  /// Maximum 250 eDTUs available per each database.
  { label: 'eDTU_250', value: '250' }, // 0x000000FA
  /// Maximum 500 eDTUs available per each database.
  { label: 'eDTU_500', value: '500' }, // 0x000001F4
  /// Maximum 1000 eDTUs available per each database.
  { label: 'eDTU_1000', value: '1000' }, // 0x000003E8
  /// Maximum 1750 eDTUs available per each database.
  { label: 'eDTU_1750', value: '1750' }, // 0x000006D6
  /// Maximum 4000 eDTUs available per each database.
  { label: 'eDTU_4000', value: '4000' }, // 0x00000FA0
];

const SqlElasticPoolPremiumSorage = [
  { label: 'Max50Gb', value: '53687091200' }, // 0x0000000C80000000
  /// 100 GB storage capacity available for the databases.
  { label: 'Max100Gb', value: '107374182400' }, // 0x0000001900000000
  /// 150 GB storage capacity available for the databases.
  { label: 'Max150Gb', value: '161061273600' }, // 0x0000002580000000
  /// 200 GB storage capacity available for the databases.
  { label: 'Max200Gb', value: '214748364800' }, // 0x0000003200000000
  /// 250 GB storage capacity available for the databases.
  { label: 'Max250Gb', value: '268435456000' }, // 0x0000003E80000000
  /// 300 GB storage capacity available for the databases.
  { label: 'Max300Gb', value: '322122547200' }, // 0x0000004B00000000
  /// 400 GB storage capacity available for the databases.
  { label: 'Max400Gb', value: '429496729600' }, // 0x0000006400000000
  /// 500 GB storage capacity available for the databases.
  { label: 'Max500Gb', value: '536870912000' }, // 0x0000007D00000000
  /// 750 GB storage capacity available for the databases.
  { label: 'Max750Gb', value: '805306368000' }, // 0x000000BB80000000
  /// 800 GB storage capacity available for the databases.
  { label: 'Max800Gb', value: '858993459200' }, // 0x000000C800000000
  /// 1 TB storage capacity available for the databases.
  { label: 'Max1Tb', value: '1099511627776' }, // 0x0000010000000000
  /// 1200 GB storage capacity available for the databases.
  { label: 'Max1200Gb', value: '1288490188800' }, // 0x0000012C00000000
  /// 1280 GB storage capacity available for the databases.
  { label: 'Max1280Gb', value: '1374389534720' }, // 0x0000014000000000
  /// 1536 GB storage capacity available for the databases.
  { label: 'Max1536Gb', value: '1649267441664' }, // 0x0000018000000000
  /// 1600 GB storage capacity available for the databases.
  { label: 'Max1600Gb', value: '1717986918400' }, // 0x0000019000000000
  /// 1792 GB storage capacity available for the databases.
  { label: 'Max1792Gb', value: '1924145348608' }, // 0x000001C000000000
  /// 2000 GB storage capacity available for the databases.
  { label: 'Max2000Gb', value: '2147483648000' }, // 0x000001F400000000
  /// 2 TB storage capacity available for the databases.
  { label: 'Max2Tb', value: '2199023255552' }, // 0x0000020000000000
  /// 2304 GB storage capacity available for the databases.
  { label: 'Max2304Gb', value: '2473901162496' }, // 0x0000024000000000
  /// 2560 GB storage capacity available for the database.
  { label: 'Max2560Gb', value: '2748779069440' }, // 0x0000028000000000
  /// 2816 GB storage capacity available for the database.
  { label: 'Max2816Gb', value: '3023656976384' }, // 0x000002C000000000
  /// 3000 GB storage capacity available for the databases.
  { label: 'Max3000Gb', value: '3221225472000' }, // 0x000002EE00000000
  /// 3 TB storage capacity available for the databases.
  { label: 'Max3Tb', value: '3298534883328' }, // 0x0000030000000000
  /// 3328 GB storage capacity available for the database.
  { label: 'Max3328Gb', value: '3573412790272' }, // 0x0000034000000000
  /// 3584 GB storage capacity available for the database.
  { label: 'Max3584Gb', value: '3848290697216' }, // 0x0000038000000000
  /// 3840 GB storage capacity available for the database.
  { label: 'Max3840Gb', value: '4123168604160' }, // 0x000003C000000000
  /// 4 TB storage capacity available for the database.
  { label: 'Max4Tb', value: '4398046511104' }, // 0x0000040000000000
];

const PoolOptions = {
  Basic: [SqlElasticPoolBasicMinEDTUs, SqlElasticPoolBasicMaxEDTUs, undefined],
  Standard: [
    SqlElasticPoolStandardMinEDTUs,
    SqlElasticPoolStandardMaxEDTUs,
    SqlElasticPoolStandardStorage,
  ],
  Premium: [
    SqlElasticPoolPremiumMinEDTUs,
    SqlElasticPoolPremiumMaxEDTUs,
    SqlElasticPoolPremiumSorage,
  ],
};

export {
  SqlElasticPoolBasicMinEDTUs,
  SqlElasticPoolBasicMaxEDTUs, //No storage for Basic pools
  SqlElasticPoolStandardMinEDTUs,
  SqlElasticPoolStandardMaxEDTUs,
  SqlElasticPoolStandardStorage,
  SqlElasticPoolPremiumMinEDTUs,
  SqlElasticPoolPremiumMaxEDTUs,
  SqlElasticPoolPremiumSorage,
  ElasticPoolEdition,
  PoolOptions,
};
