import axios from 'axios';

import { authClient } from '../auth/authClient';

axios.interceptors.request.use(
  async function (config) {
    const token = await authClient.getAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const apiClient = axios;
