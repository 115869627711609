import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import { loginRequest, msalConfig } from './authConfig';

export class AuthClient extends PublicClientApplication {
  accessTokenRequestPromise = undefined;

  login(request = loginRequest) {
    return this.loginRedirect(request);
  }

  logout() {
    return this.logoutRedirect();
  }

  async getAccessToken(type = 'redirect') {
    const showMessageAndLogOut = () => {
      alert(
        'Authentication failed, unable to retrieve access token. Please log in again.'
      );
      this.logout();
    };

    if (this.accessTokenRequestPromise) {
      return this.accessTokenRequestPromise;
    }
    const accounts = this.getAllAccounts();
    if (!accounts.length) {
      showMessageAndLogOut();
      return;
    }
    const account = accounts[0];
    const accessTokenRequest = {
      scopes: loginRequest.scopes,
      account,
    };
    this.accessTokenRequestPromise = this.acquireTokenSilent(accessTokenRequest)
      .then(
        ({ accessToken }) => accessToken,
        async (e) => {
          if (e instanceof InteractionRequiredAuthError) {
            console.warn(
              `Unable to silently acquire access token. ${e.message}. Using redirect to re-authenticate.`
            );
            if (type === 'redirect') {
              void authClient.acquireTokenRedirect(accessTokenRequest);
            } else {
              try {
                const { accessToken } = await this.acquireTokenPopup(
                  accessTokenRequest
                );
                return accessToken;
              } catch (popupError) {
                console.error(
                  `Unable to acquire access token from popup: ${popupError.message}`
                );
                throw popupError;
              }
            }
          }
          console.error(`Unknown access token acquire error: ${e.message}`);
          throw e;
        }
      )
      .catch(() => {
        showMessageAndLogOut();
      })
      .finally(() => {
        this.accessTokenRequestPromise = undefined;
      });
    return this.accessTokenRequestPromise;
  }
}

export const authClient = new AuthClient(msalConfig);
