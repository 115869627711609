import * as yup from 'yup';
import { useState, useEffect, Fragment } from 'react';
import classnames from 'classnames';
import { selectThemeColors, isObjEmpty } from '@utils';
import { useForm } from 'react-hook-form';
import { ArrowLeft, ArrowRight, AlertCircle, PlusCircle } from 'react-feather';
import {
  Form,
  Label,
  Input,
  FormGroup,
  Row,
  Col,
  Button,
  Alert,
  Spinner,
  Badge,
} from 'reactstrap';
import styles from '../../../styles.module.css';
import Select from 'react-select';
import * as epOpts from '../../../../utility/ElasticPoolOptions';
import { apiClient } from '../../../../api/apiClient';

import * as config from '../../../../config/config';

import '@styles/react/libs/react-select/_react-select.scss';

const ElasticPools = ({
  stepper,
  type,
  createData,
  setCreateData,
  enabled,
}) => {
  const { errors, handleSubmit, trigger } = useForm();
  // List of EPs from API
  const [existingPools, setExistingPools] = useState([]);
  // Chosen EP name from drop down
  const [existingPool, setExistingPool] = useState(null);

  // new EP name & settings
  const [newPoolName, setNewPoolName] = useState('');
  const [poolEdition, setPoolEdition] = useState(null);
  const [minDTU, setMinDTU] = useState(null);
  const [maxDTU, setMaxDTU] = useState(null);
  const [storage, setStorage] = useState(null);
  // set if new server already exists (is in list from API)
  const [poolExists, setPoolExists] = useState(false);
  const [createDisabled, setCreateDisabled] = useState(true); // until name & region are set

  //General API error box & fetching flag
  const [error, setError] = useState({ hasError: false, httpError: {} });
  const [isFetching, setIsFetching] = useState(false);

  const [nextDisabled, setNextDisabled] = useState(true); // until created

  const onSubmit = () => {
    trigger();
    if (isObjEmpty(errors)) {
      setCreateData({
        ...createData,
        elasticPool: existingPool?.value || newPoolName,
      });
      stepper.next();
    }
  };

  const fetch = () => {
    // if we dont have a resource group, server OR we've already fetched, do nothing.
    if (createData.resourceGroup === '' || createData.sqlServer === '') {
      return;
    }
    setExistingPools([]);

    setIsFetching(true);
    apiClient
      .get(
        `${config.REACT_APP_ONBOARD_API}/Azure/elastic-pools/${createData.resourceGroup}/${createData.sqlServer}`
      )
      .then((response) => {
        setExistingPools(
          response.data.map((pool, idx) => ({
            value: pool.name,
            label: pool.name,
          }))
        );
        setError({ hasError: false, httpError: undefined });
      })
      .catch((err) => {
        console.error(err);
        setError({ hasError: true, httpError: err });
      })
      .finally(() => {
        setIsFetching(false);
      });
  };
  const retryFetch = () => {
    setError({ hasError: false, httpError: undefined });
    fetch();
  };

  useEffect(() => {
    if (enabled) {
      fetch();
    }
  }, [enabled]);

  useEffect(() => {
    if (!createData.elasticPool || !createData.sqlServer) {
      setExistingPool(null);
    }
    if (!createData.sqlServer) {
      setExistingPools([]);
    }
  }, [createData]);

  useEffect(() => {
    if (existingPools.length) {
      setExistingPool(
        existingPools.find((pool) => pool.value === createData.elasticPool) ||
          null
      );
    }
  }, [createData.elasticPool, existingPools]);

  //Choose an existing Pool
  const onDropdownChange = (val) => {
    setExistingPool(val);
    setNewPoolName('');
    setPoolEdition(null);
    setMinDTU(null);
    setMaxDTU(null);
    setStorage(null);
    setCreateDisabled(true);
    setNextDisabled(false);
  };

  const onChangeEditionHandler = (val) => {
    setPoolEdition(val);
    if (val === null) {
      setMinDTU(null);
      setMaxDTU(null);
      setStorage(null);
      setCreateDisabled(true);
    } else {
      setCreateDisabled(!(newPoolName.length > 6));
    }
  };

  const onNewPoolNameChange = (e) => {
    const poolName = e.target.value;
    setExistingPool(null); // clear out existing Pool DD
    setNextDisabled(true); // Disable NEXT until created.
    setNewPoolName(poolName);
    //make sure the name isn't in the pool collection from the API
    const exists = existingPools.some((g) => g.value === poolName);
    setPoolExists(exists);

    const hasEdition = poolEdition !== null;
    // Enable CREATE button only if name > 6 , it doesn't already exist & a region is set.
    setCreateDisabled(!(poolName.length > 6 && hasEdition && !exists));
  };

  const createNewPool = () => {
    setCreateDisabled(
      !(newPoolName.length > 6 && poolEdition !== null && !poolExists)
    );
    setNextDisabled(false);
  };
  if (existingPools === null) return null;
  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Alert color="info">
          <div className="alert-body">
            <AlertCircle size={24} /> <span>Using resource group </span>
            <span className="font-weight-bold">{createData.resourceGroup}</span>
            <span> with SQL Server </span>{' '}
            <span className="font-weight-bold">{createData.sqlServer}</span>
          </div>
        </Alert>
        <Row>
          <Col className="mb-1" md="6" sm="12">
            <div className="content-header">
              <h5 className="mb-0">Elastic Pool configuration.</h5>
              <small>
                Choose an existing Elastic Pool or create a new one.
              </small>
            </div>
            <FormGroup>
              <Label className="form-label" for="rgSelect">
                {isFetching && <Spinner size="sm" color="success"></Spinner>}
                <span>&nbsp;Existing Pools&nbsp;</span>
                <Badge pill color="success" className={classnames.Badge}>
                  {existingPools.length}
                </Badge>
              </Label>
              <Select
                theme={selectThemeColors}
                className="react-select"
                classNamePrefix="select"
                options={existingPools}
                value={existingPool}
                isClearable={true}
                onChange={onDropdownChange}
              />
            </FormGroup>
          </Col>

          {/* Create a new pool */}

          <Col className="mb-1" md="6" sm="12">
            <div className="content-header">
              <h5 className="mb-0">Create New Elastic Pool</h5>
              <small>
                Azure standard format = 'sql-serverName-environment-region-nnn'
              </small>
            </div>

            <FormGroup>
              <Label className="form-label" for="newRg">
                New Elastic Pool Name
              </Label>
              <Input
                type="text"
                id="newPoolName"
                placeholder="New pool name"
                value={newPoolName}
                onChange={onNewPoolNameChange}
              />
              <div className="err-msg mt-1" hidden={!poolExists}>
                <Alert color="danger">Pool already exists.</Alert>
              </div>
            </FormGroup>
            <FormGroup>
              <Label className="form-label" for="pool-edition">
                Pool Edition
              </Label>
              <Select
                id="pool-edition"
                theme={selectThemeColors}
                className="react-select"
                classNamePrefix="select"
                options={epOpts.ElasticPoolEdition}
                isClearable={true}
                value={poolEdition}
                onChange={onChangeEditionHandler}
              />
            </FormGroup>
            <FormGroup>
              <Label className="form-label" for="min-DTU">
                Database Min DTU
              </Label>
              <Select
                id="min-DTU"
                theme={selectThemeColors}
                className="react-select"
                classNamePrefix="select"
                options={
                  poolEdition === null
                    ? { label: '', value: 0 }
                    : epOpts.PoolOptions[poolEdition.value][0]
                }
                isClearable={true}
                value={minDTU}
                onChange={(e) => setMinDTU(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="form-label" for="max-DTU">
                Database Max DTU
              </Label>
              <Select
                id="max-DTU"
                theme={selectThemeColors}
                className="react-select"
                classNamePrefix="select"
                options={
                  poolEdition === null
                    ? { label: '', value: 0 }
                    : epOpts.PoolOptions[poolEdition.value][1]
                }
                isClearable={true}
                value={maxDTU}
                onChange={(e) => setMaxDTU(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="form-label" for="storage">
                Storage capacity
              </Label>
              <Select
                id="storage"
                theme={selectThemeColors}
                className="react-select"
                classNamePrefix="select"
                options={
                  poolEdition === null
                    ? { label: '', value: 0 }
                    : epOpts.PoolOptions[poolEdition.value][2]
                }
                isClearable={true}
                value={storage}
                onChange={(e) => setStorage(e)}
              />
            </FormGroup>

            <Button.Ripple
              color="success"
              onClick={() => createNewPool()}
              disabled={createDisabled}
            >
              <span className="align-middle d-sm-inline-block d-none">
                Create
              </span>
              <PlusCircle
                size={14}
                className="align-middle ml-sm-25 ml-0"
              ></PlusCircle>
            </Button.Ripple>
          </Col>
        </Row>

        <div className="d-flex justify-content-between">
          <Button.Ripple
            color="primary"
            className="btn-prev"
            onClick={() => stepper.previous()}
          >
            <ArrowLeft
              size={14}
              className="align-middle mr-sm-25 mr-0"
            ></ArrowLeft>
            <span className="align-middle d-sm-inline-block d-none">
              Previous
            </span>
          </Button.Ripple>
          <Button.Ripple
            type="submit"
            color="primary"
            className="btn-next"
            disabled={nextDisabled}
          >
            <span className="align-middle d-sm-inline-block d-none">Next</span>
            <ArrowRight
              size={14}
              className="align-middle ml-sm-25 ml-0"
            ></ArrowRight>
          </Button.Ripple>
        </div>
      </Form>

      {error.hasError === true && (
        <div className="mt-2">
          <Alert className={styles.redbox}>
            <h2
              style={{ color: '#ccc', fontSize: '1.5rem' }}
              className="alert-heading shadow-none"
            >
              An error has occurred
            </h2>
            <div style={{ margin: '16px' }}>
              <p className="mb-1">{error.httpError.message}</p>
              <hr />
              <Button
                size="sm"
                className="mb-1"
                color="primary"
                onClick={retryFetch}
              >
                Retry
              </Button>
            </div>
          </Alert>
        </div>
      )}
    </Fragment>
  );
};

export default ElasticPools;
