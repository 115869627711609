import mock from './mock';
import './tables/datatables';
import './autoComplete/autoComplete';
import './navbar/navbarSearch';
import './pages/faq';
import './cards/card-analytics';
import './cards/card-statistics';
import './jwt';

mock.onAny().passThrough();
